import React from "react"
import { Box, Text } from "theme-ui"
import SEO from "../../../components/SEO"
import Layout from "../../../components/Layout"
import PageHeader from "../../../components/PageHeader"
import ContentSection from "../../../components/ContentSection"

const SscPage = () => {
  return (
    <Box>
      <SEO title="Ssc" />
      <Layout>
        <PageHeader>
          <Text
            sx={{ fontSize: [3, 5], fontWeight: "bold", textAlign: "center" }}
          >
            SSC
          </Text>
        </PageHeader>
        <ContentSection
          header="SSC"
          body={
            <Box
              sx={{
                maxWidth: "800px",
                marginTop: ["-50px", "auto"],
                marginLeft: "auto",
                marginRight: "auto",
                marginBottom: "auto",
                textAlign: "justify",
              }}
            >
              <Text sx={{ fontSize: [0, 3] }}>
                SSC Exams stands for Staff Selection Commission, a government
                organization in India that conducts exams to recruit staff for
                various posts in ministries, departments, and organizations. The
                exams include the combined graduate level (CGL), Combined Higher
                Secondary level (CHSL), and others. Successfully passing SSC
                exams can lead to job opportunities in government offices,
                offering stability, benefits, and a decent salary.
              </Text>

              <Text sx={{ fontSize: [0, 3] }} mt={4}>
                SSC exams are open to Indian citizens aged 18 to 32, with
                varying educational qualifications depending on the specific
                exam. Common eligibility includes a bachelor’s degree for the
                Combined Graduate Level (GGL) exam and a higher secondary (10+2)
                qualification for the Combined Higher Secondary Level (CHSL)
                exam.
              </Text>
            </Box>
          }
        />
        <ContentSection
          header="BENEFITS OF QUALIFYING FOR SSC EXAMS INCLUDE:"
          body={
            <Box
              sx={{
                maxWidth: "800px",
                marginTop: ["-50px", "auto"],
                marginLeft: "auto",
                marginRight: "auto",
                marginBottom: "auto",
                textAlign: "justify",
              }}
            >
              <Text sx={{ fontSize: [0, 3] }}>
                1. <span style={{ fontWeight: "800" }}>JOB SECURITY:</span>
                Government jobs through SSC provide stability and long-term
                employment
              </Text>

              <Text sx={{ fontSize: [0, 3] }} mt={4}>
                2. <span style={{ fontWeight: "800" }}>ATTRACTIVE SALARY:</span>{" "}
                SSC posts come with competitive pay scales and allowances.
              </Text>
              <Text sx={{ fontSize: [0, 3] }} mt={4}>
                3.{" "}
                <span style={{ fontWeight: "800" }}>
                  PENSION AND OTHER BENEFITS:{" "}
                </span>
                Government employees often receive pension benefits and other
                perks like medical facilities
              </Text>
              <Text sx={{ fontSize: [0, 3] }} mt={4}>
                4. <span style={{ fontWeight: "800" }}>CAREER GROWTH:</span>{" "}
                Opportunities for promotions and career advancement within
                government services
              </Text>
              <Text sx={{ fontSize: [0, 3] }} mt={4}>
                5. <span style={{ fontWeight: "800" }}>WORK LIFE BALANCE:</span>{" "}
                Generally, government jobs offer a better work-life balance
                compared to certain private section roles
              </Text>
              <Text sx={{ fontSize: [0, 3] }} mt={4}>
                6. <span style={{ fontWeight: "800" }}>SOCIAL STATUS:</span>{" "}
                Government jobs are often associated with higher social status
                and prestige.
              </Text>
              <Text sx={{ fontSize: [0, 3] }} mt={4}>
                7. <span style={{ fontWeight: "800" }}>JOB VARIETY:</span> SSC
                exams cover a range of positions, allowing candidates from
                different educational backgrounds to find suitable
                opportunities.
              </Text>
              <Text sx={{ fontSize: [0, 3] }} mt={4}>
                It's important to note that benefits may vary depending on the
                specific position and level of government service
              </Text>
            </Box>
          }
        />
      </Layout>
    </Box>
  )
}
export default SscPage
